.c-accordions {
	background: $base--bg-colour;
	display: block;
	font-size: 0;
	overflow: visible;
	position: relative;
	width: 100%;

	.c_inner {
		padding: 20px 5vw 140px;
		position: relative;
		z-index: 1;

		@media (--lg-up) { padding: 20px 40px 190px; }

	}

		.c_accordion {
			backdrop-filter: blur(26px);
			background: rgba(34,26,45,.5);
			border: 1px solid #372B45;
			border-radius: 10px;
			box-shadow: 0px 72px 74px -20px rgba(0, 0, 0, 0.275623);
			display: block;
			margin: 0 auto;
			max-width: 1024px;

			&:not(:last-of-type) { margin-bottom: 10px; }

		}

			.accordion_head {
				align-items: center;
				cursor: pointer;
				display: flex;
				justify-content: flex-start;
				min-height: 120px;
				padding: 18px 90px 18px 25px;
				position: relative;

				@media (--lg-up) { padding: 35px 110px 35px 50px; }

			}

				.head_title {
					display: inline-flex;
					flex: 1 1 100%;
					font-weight: 400;
					font-size: responsive 18px 25px;
					font-range: 420px 1280px;
					line-height: 1.35em;
					width: 100%;
				}

				.head_arrow {
					background-image: linear-gradient(to right, $c--3, $c--4);
					border-radius: 100%;
					margin: auto 0;
					size: 46px;
					position: absolute 0 32px 0 *;

					&::before, &::after {
						content: "";
						display: block;
						margin: auto;
						position: absolute 0;
					}

					&::before {
						background: #1d1528;
						border-radius: 100%;
						size: calc(100% - 2px);
						z-index: 0;
					}

					&::after {
						background-image: url("data:image/svg+xml,%3Csvg width='14' height='9' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 1L7 7L1 0.999999' stroke='%23ECE5CB' stroke-width='2'/%3E%3C/svg%3E%0A");
						size: 14px 9px;
						transform: rotate(0deg);
						transition: transform .6s ease;
						z-index: 1;
					}

				}

				.c_accordion.is-open .head_arrow::after { transform: rotate(180deg); }

			.accordion_value {
				display: block;
				padding: 0 25px 45px;
				width: 100%;

				@media (--lg-up) { padding: 0 50px 55px; }

			}

}
