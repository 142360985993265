.c-cta-social {
	background: $base--bg-colour;
	display: block;
	font-size: 0;
	overflow: hidden;
	position: relative;
	text-align: center;
	width: 100%;

	.c_inner {
		padding: 70px 5vw 150px;
		position: relative;

		@media (--lg-up) { padding: 0 40px 160px; }

	}

		.c_content {
			display: inline-block;
			margin: 0 auto;
		}

			.content_upper-title {
				display: block;
				margin-bottom: 40px;
			}

			.content_heading {
				@extend .u-family--4;
				display: flex;
				font-size: responsive 28px 72px;
			    font-range: 420px 1280px;
				font-weight: 400;
				letter-spacing: -.035em;
			    line-height: 1em;
				margin-top: 25px;
				text-transform: uppercase;
        justify-content: center;
			}

      .heading_img {
        max-height: 3em;
        max-width: 3em;
        margin: -0.3em;

        &::before { margin-bottom: 100% }
      }

				.heading_highlight { @extend .u-text-style--1; }

			.content_text {
			    color: $base--text-colour;
				display: block;
				margin: 25px auto 0;
				max-width: 680px;
				width: 100%;

				p {
					display: block;
				    font-size: responsive 20px 24px;
				    font-range: 420px 1280px;
				    line-height: 1.4em;

					&:not(:last-of-type) { margin-bottom: 1em; }

				}

			}

		.c_socials {
			align-items: center;
			display: inline-flex;
			flex-wrap: wrap;
			justify-content: center;
			margin: 40px auto 0;
			width: 100%;

			&:hover .socials_el svg { opacity: .4; }

		}

			.socials_el {
				align-items: center;
				border: 1px solid $c--3;
				border-radius: 100%;
				display: inline-flex;
				justify-content: center;
				size: 48px;
				pointer-events: auto;
				text-decoration: none;

				@media (--md-up) { size: 88px; }

				&:not(:first-of-type) { margin-left: 5px; }
				&:not(:last-of-type) { margin-right: 5px; }

				&:hover svg { opacity: 1 !important; }

			}

				svg {
					display: inline-flex;
					margin: auto;
					max-height: 20px;
					max-width: 20px;
					transition: all .5s ease;
					vertical-align: middle;

					@media (--md-up) {
						max-height: 100%;
						max-width: 100%;
					}

					path { fill: #fff; }

				}

}
