.c-ft-montage {
	background: $base--bg-colour;
	display: block;
	font-size: 0;
	overflow: hidden;
	position: relative;
	text-align: center;
	width: 100%;
  margin-bottom: 50px;

	@media (--lg-up) {
		padding-top: 110px;
		text-align: left;
	}

	.c_section {
		display: block;
		overflow: visible;
		position: relative;
		width: 100%;

		@media (--lg-up) {
			align-items: stretch;
			display: flex;
		}

	}

		.section_col {
			align-items: center;
			display: flex;
			justify-content: center;
			overflow: visible;
			position: relative;
			width: 100%;

			@media (--lg-up) {
				display: inline-flex;
				flex: 0 0 50%;
        width: 50%;
			}

		}

		.section_media {
      @media (--lg-up) { align-items: flex-end; }
		}

			.media_image {
				margin: 0 auto;
				overflow: visible !important;
				position: relative;
			    width: 90%;
				z-index: 1;

				&::before {
					bottom: 0;
					top: auto !important;
				}

				@media (--lg-up) {
					margin: 0;
					position: absolute * * 0;
				}

			}

				.media_img { @extend %u-absolute-fill, .u-cover-object; }

			.image_prop {
				display: none;
				margin: 0 auto;
				opacity: 0;
				position: absolute;
				width: 100%;
				z-index: 0;

				@media (--lg-up) {
					display: inline-block;
					margin: 0;
					position: relative;
				}

			}

		.section_head {
			margin-bottom: 40px;
			text-align: center;

			@media (--xl-up) {
				left: calc(50% - 615px);
				margin-bottom: 0;
				right: auto;
				text-align: left;
			}
      @media (--lg-up) { position: absolute 0 0 *;}
		}

    .canvas_media {
			@extend %u-absolute-fill;
			overflow: hidden;
			pointer-events: none;
			user-select: none;
			z-index: 0;
		}

			.media_video {
				object-fit: cover;
				object-position: center top;
				position: absolute -10px 0 0;
				size: calc(100% + 10px) 100%;
        opacity: 0;
				z-index: 1;
			}

      .head_title2 {
        @extend .u-family--4;
				align-items: center;
				display: flex;
				flex-direction: column;
				font-size: responsive 20px 24px;
				font-range: 420px 1280px;
				font-weight: 400;
				justify-content: center;
				letter-spacing: -.035em;
				line-height: .875em;
				padding-top: 60px;
				position: relative;
				text-align: center;
				text-transform: uppercase;
				white-space: nowrap;
				z-index: 1;

				@media (--lg-up) {
					align-items: flex-start;
					justify-content: flex-start;
					text-align: left;
          padding-left: 25px;
				}
      }

			.head_title {
				@extend .u-family--4;
				align-items: center;
				display: flex;
				flex-direction: column;
				font-size: responsive 20px 80px;
				font-range: 420px 1900px;
				font-weight: 400;
				justify-content: center;
				letter-spacing: -.035em;
				line-height: .875em;
				padding-top: 30px;
				position: relative;
				text-align: center;
				text-transform: uppercase;
				white-space: nowrap;
				z-index: 1;

				@media (--xl-up) {
					align-items: flex-start;
					justify-content: flex-start;
					text-align: left;
				}

			}

      .content_btn { 
        margin-top: 30px; 
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media(--xs-up) {
          flex-direction: row;
          justify-content: center;
        }

        @media(--lg-up) {
          margin-left: 25px;
        }

        .btn_link {
          margin-right: 20px;
          margin-bottom: 20px;

          @media(--lg-up) {
            margin-top: 0px;
          }
        }
      }

				.title_line { display: flex; }

					.line_text { display: inline-flex; }

					.line_highlight { @extend .u-text-style--1; }

			.head_backdrop {
				@extend .u-family--4;
				color: rgba(255,255,255,.05);
				letter-spacing: -.025em;
				margin: 0;
				pointer-events: none;
				position: absolute 0 * * calc(50% - 820px);
				text-align: center;
				user-select: none;
				z-index: 0;
			}

		.section_content {
			padding: 0 4vw;
		    text-align: center;
      align-items: flex-end;

			@media (--lg-up) {
				flex: 0 0 50%;
				padding: 0;
			    text-align: left;
        width: 45%;
			}

		}

			.content_heading {
				margin-bottom: 20px;

				@media (--lg-up) { margin-bottom: 40px; }

			}

			.content_text {
				color: $base--text-colour;
				display: block;
				margin: 0 auto;
				max-width: 680px;
				width: 100%;

				@media (--lg-up) {
					border-left: 1px solid #E57655;
					margin: * 0;
					padding: 0 0 0 25px;
				}

				p {
					display: block;
					font-size: responsive 20px 24px;
					font-range: 420px 1280px;
					line-height: 1.4em;

					&:not(:last-of-type) { margin-bottom: 1em; }

				}

			}

      .content_text2 {
				color: $base--text-colour;
				display: block;
				margin: 0 auto;
				max-width: 680px;
				width: 100%;

				@media (--lg-up) {
					margin: * 0;
					padding: 0 0 0 25px;
				}

				p {
					display: block;
					font-size: responsive 14px 18px;
					font-range: 420px 1280px;
					line-height: 1.4em;

					&:not(:last-of-type) { margin-bottom: 1em; }

				}

			}

	.c_section:nth-of-type(1) {
		z-index: 1;

		.media_image,
		.image_prop {
			max-height: 1200px;
			max-width: 750px;
		}

		.media_image {

			@media (--lg-up) { right: 50px; }

			&::before { padding-bottom: 108%; }

		}

		.image_prop::before { padding-bottom: 140%; }

		.section_content {

			@media (--lg-up) { justify-content: flex-start; }

		}

			.title_line {

				@media (--xxl-up) {
					&:nth-of-type(2) { padding-left: 80px; }
					&:nth-of-type(3) { padding-left: 160px; }
				}

				@media (--xl-xxl) {
					&:nth-of-type(1) { padding-left: 60px; }
					&:nth-of-type(2) { padding-left: 110px; }
					&:nth-of-type(3) { padding-left: 160px; }
				}

				@media (--xxl-down) { transform: none !important; }

			}

			.content_inner {

				@media (--lg-up) { max-width: 600px; padding-bottom: 6rem;}

			}

	}

	.c_section:nth-of-type(2) {
		z-index: 0;

		@media (--lg-up) {
			flex-direction: row-reverse;
			/* margin-top: 170px; */
		}

		.media_image,
		.image_prop {
			max-height: 1305px;
			max-width: 862px;
		}

		.media_image {

			@media (--lg-up) { left: 50px; }

			&::before { padding-bottom: 130%; }

		}

		.image_prop::before { padding-bottom: 80%; }

		.section_content {

			@media (--lg-up) {
				justify-content: flex-end;
				padding-bottom: 150px;
			}

		}

			.content_inner {

				@media (--lg-up) { max-width: 620px; }

			}

	}

}
