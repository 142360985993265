.c-home-hero {
	background: $base--bg-colour;
	display: block;
	font-size: 0;
	position: relative;
	text-align: center;

	.hero_canvas {
		align-items: center;
		display: flex;
		flex-direction: column;
		height: calc(100vh - 80px);
		height: calc(calc(var(--vh, 1vh) * 100) - 80px);
		max-height: 620px;
		min-height: 568px;
		justify-content: center;
		overflow: visible;
		padding: 120px 0 180px;
		position: relative;
		z-index: 0;

		@media (--sm-up) {
			max-height: none;
			min-height: 780px;
		}

		&::before {
			background: linear-gradient(180deg, rgba(54, 37, 74, 0) 0%, #191024 100%);
			content: "";
			display: block;
			max-height: 390px;
			position: absolute * 0 -1px;
			size: 100% 50%;
			z-index: 1;
		}

		@media (--lg-up) {
			min-height: 680px;
			padding-bottom: 80px;
		}

		.canvas_content {
			padding: 30px 5vw;
			position: relative;
			width: 100%;
			z-index: 2;

			@media (--lg-up) { padding: 60px 40px; }

		}

			.content_title {
				align-items: center;
				display: flex;
				justify-content: center;
				margin: auto;
				max-width: 828px;
				overflow: visible;
				position: relative;
			}

				.title_logo {
					display: inline-flex;
		            filter: drop-shadow(0px 4px 26px #191024);
					margin: auto;
					max-width: 100%;
					text-align: center;
					width: 100%;
				}

			.content_present {
				align-items: center;
				display: flex;
				justify-content: center;
				margin: 22px auto 0;
				position: relative;
				width: 100%;
			}

				.present_src {
					background: transparent url('../images/home-hero_gripnr.svg') no-repeat center / 233px 40px;
					display: inline-flex;
					margin: auto;
					size: 233px 40px;
				}

			.content_heading {
				@extend .u-family--4, .u-text-style--1;
				display: inline-flex;
				filter: drop-shadow(0 1px 10px rgba(0,0,0,.5));
			    font-size: responsive 18px 60px;
			    font-range: 420px 2100px;
				font-weight: 400;
				letter-spacing: -.035em;
			    line-height: 1em;
				margin: 30px auto 0;
				text-transform: uppercase;
			}

      .content_sub_heading {
        color: $base--text-colour;
        font-size: responsive 14px 45px;
        font-range: 420px 2100px;
        letter-spacing: 3px;
        line-height: 1em;
        text-transform: uppercase;
        margin-top: 1em;
        margin-right: auto;
        margin-left: auto;
        width: 90%;

        @media (--lg-up) {
          width: 80%;
        }
      }

			.content_btn { margin-top: 30px; }

		.canvas_scroll {
			display: flex;
			position: absolute * 0 20px;
			width: 100%;
			z-index: 3;

			@media (min-width: 1505px) { left: calc(50% + 475px) !important; }

			@media (--xxl-up) {
				left: calc(50% + 400px);
				right: auto;
				size: 236px;
			}

		}

			.scroll_btn {
				align-items: center;
				color: $base--text-colour;
				cursor: pointer;
				display: inline-flex;
				flex-direction: column;
				justify-content: center;
				margin: 0 auto;
				padding: 20px 5vw;
				text-decoration: none;
        		transition: all .6s ease-out-quart;
				user-select: none;

				@media (--xxl-up) {
					background: rgba(25,16,36,0);
					border: 1px solid rgba(235,229,206,.3);
					border-radius: 100%;
					overflow: hidden;
					size: 100%;

					&:hover {
						background: rgba(25,16,36,.2);
						backdrop-filter: blur(20px);
					}

				}

				@media (--xxl-down) { transform: none !important; }

				@media (--lg-up) { padding: 0; }

				.btn_label {
					color: inherit;
					font-size: 11px / 1em;
					letter-spacing: .285em;
					text-decoration: inherit;
					text-transform: uppercase;
				}

				.btn_arrow {
					background: transparent url("data:image/svg+xml,%3Csvg width='15' height='19' viewBox='0 0 15 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.18868 16.3026V0H6.79719V16.2882L0.983936 10.3856L0 11.3846L7.5 19L15 11.3846L14.0161 10.3856L8.18868 16.3026Z' fill='%23EAE5D0'/%3E%3C/svg%3E%0A") no-repeat center / 15px 19px;
					display: block;
					margin-top: 23px;
					size: 15px 19px;
				}

			}

		.canvas_socials {
			align-items: center;
			display: none;
			flex-direction: column;
			justify-content: center;
			pointer-events: none;
			position: absolute 0 * 0 50px;
			size: 24px 100vh;
			z-index: 3;

			@media (--lg-up) { display: flex; }

			&:hover .socials_el svg { opacity: .4; }

			.socials_el {
				align-items: center;
				display: inline-flex;
				justify-content: center;
				size: 24px;
				pointer-events: auto;
				text-decoration: none;

				&:not(:first-of-type) { margin-top: 10px; }
				&:not(:last-of-type) { margin-bottom: 10px; }

				&:hover svg { opacity: 1 !important; }

			}

				svg {
					display: inline-flex;
					margin: auto;
					max-width: 100%;
					transition: all .5s ease;
					vertical-align: middle;
				}

		}

		.canvas_media {
			@extend %u-absolute-fill;
			overflow: hidden;
			pointer-events: none;
			user-select: none;
			z-index: 0;
		}

			.media_video,
			.media_img {
				object-fit: cover;
				object-position: center top;
				position: absolute -10px 0 0;
				size: calc(100% + 10px) 100%;
			}

			.media_video {
				opacity: 0;
				z-index: 1;

				/* iframe {
				    min-height: 100vh;
				    min-width: 177.77vh;
				    position: absolute 50% * * 50%;
				    size: 100vw 56.25vw;
				    transform: translate(-50%, -50%);
				} */

			}

			.media_img {
				object-fit: cover;
				object-position: center top;
				opacity: .5;
				z-index: 0;
			}

	}

	.hero_intro {
		align-items: stretch;
		background: transparent;
		display: flex;
		justify-content: center;
		min-height: 402px;
		margin: -1px auto 0;
		overflow: visible;
		position: relative;
		z-index: 1;

		.intro_inner {
			align-items: center;
			display: flex;
			justify-content: center;
		}

			.intro_text {
				position: relative;
				z-index: 1;
			}

				.text_title {
					@extend .u-family--3;
				    font-size: responsive 18px 39px;
				    font-range: 420px 1280px;
					font-weight: 400;
					letter-spacing: -.035em;
				    line-height: 1em;
					margin: auto;
					max-width: 880px;
					text-transform: uppercase;
				}

					.text_mark {
						@extend .u-text-style--1;
						background-color: transparent;
					}

			.intro_bg {
				@extend %u-absolute-fill;
				align-items: center;
				display: flex;
				justify-content: center;
				margin: auto;
				pointer-events: none;
				user-select: none;
				z-index: 0;
			}

				.bg_img {
					animation: u-animation_rotate--1 100s linear infinite;
					display: inline-block;
					margin: auto;
					max-height: 247px;
	    			max-width: 270px;
					opacity: .6;
					text-align: center;
					width: 100%;

					@media (--md-up) {
						max-height: 402px;
						max-width: 439px;
					}

				}

	}

}

/**
* Lazy-load
*/
html[data-page="home"] .c-home-hero {

	.canvas_socials {
		opacity: 0;
		transform: translateX(-100%);
	}

}
