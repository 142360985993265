.c-ft-list {
	background: $base--bg-colour;
	display: block;
	font-size: 0;
	overflow: hidden;
	padding: 55px 0 60px;
	position: relative;
	text-align: center;
	width: 100%;

	@media (--lg-up) {
		padding: 105px * 200px;
		text-align: left;
	}

	.c_inner {
		padding: 0 5vw;
		position: relative;
		z-index: 1;

		@media (--lg-up) { padding: * 40px; }

	}

		.c_main {
			display: block;
			position: relative;
			width: 100%;
			z-index: 1;

			@media (--lg-up) {
				align-items: flex-start;
				display: grid;
				grid-template-columns: repeat(2, 1fr);
			}

		}

      .c_content {
        margin-top: 50px;

				@media (--lg-up) { margin-top: 0; }
      }

      .content_btn {
        display: flex;
        flex-direction: column;
        align-items: center;

        @media(--lg-up) {
          flex-direction: row;
          justify-content: initial;
          margin-left: 80px
        }

        .btn_link {
          margin-right: 20px;
          margin-bottom: 20px;

          @media(--lg-up) {
            margin-bottom: 0px;
          }
        }
      }
      
			.c_col {
				display: block;
				width: 100%;
			}

				.content_text {
				    color: $base--text-colour;
					display: block;
					margin: 0 auto;
					max-width: 680px;
					width: 100%;

					@media (--lg-up) {
						margin: * 0;
						padding: 10px 0 0 80px;
					}

					p {
						display: block;
					    font-size: responsive 20px 24px;
					    font-range: 420px 1280px;
					    line-height: 1.4em;
						  margin-bottom: 1em;

					}

				}

			.c_list { }

				.list_items {
					display: block;
					width: 100%;
				}

					.items_el {
						@extend .u-family--3, .u-text-style--1;
						display: flex;
					    font-size: responsive 16px 24px;
					    font-range: 420px 1280px;
						font-weight: 400;
						justify-content: center;
						letter-spacing: -.035em;
					    line-height: .875em;
						padding: .65em 0 .5em;
						position: relative;
						text-align: center;
						text-transform: uppercase;
						width: 100%;

						@media (--lg-up) {
							justify-content: flex-start;
							text-align: left;
						}

						&:not(:last-of-type)::after {
							background: rgba(235,229,206,.35);
							content: "";
							position: absolute * 0 0;
							size: 100% 1px;
						}

					}

	.c_bg {
		@extend %u-absolute-fill;
    display: flex;
    justify-content: center;
    top: -100px;
		opacity: 0;
		pointer-events: none;
		user-select: none;
		transition: opacity 1s ease;
		z-index: 0;
	}

	&.is-inview .c_bg { opacity: 1; }

		.bg_src {
			@extend %u-absolute-fill, .u-cover-object;
      max-width: 1600px;
      left: unset;
			opacity: .9;
		}

}
