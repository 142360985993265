.c-team {
	background: $base--bg-colour;
	display: block;
	font-size: 0;
	overflow: visible;
	position: relative;
	text-align: left;
	width: 100%;

	.c_inner {
		overflow: visible;
		padding: 0 5vw 125px;
		position: relative;
		z-index: 1;

		@media (--lg-up) { padding: * 40px 260px; }

	}

		.c_list {
			display: block;
			position: relative;
			width: 100%;

			@media (--lg-up) {
				align-items: flex-start;
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: space-between;
			}

		}

			.list_scroll {
				align-items: center;
				background: rgba(25,16,36,0);
				border: 1px solid rgba(235,229,206,.3);
				border-radius: 100%;
				color: $base--text-colour;
				display: none;
				flex-direction: column;
				justify-content: center;
				overflow: hidden;
				padding: 0;
				position: absolute -50px 0 * *;
				size: 236px;
				text-decoration: none;
        		transition: all .6s ease-out-quart;
				user-select: none;
				z-index: 2;

				@media (--sm-up) {
					display: inline-flex;

					&:hover {
						background: rgba(25,16,36,.2);
						backdrop-filter: blur(20px);
					}

				}

				.btn_label {
					color: inherit;
					font-size: 11px / 1em;
					letter-spacing: .285em;
					text-decoration: inherit;
					text-transform: uppercase;
				}

				.btn_arrow {
					background: transparent url("data:image/svg+xml,%3Csvg width='15' height='19' viewBox='0 0 15 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.18868 16.3026V0H6.79719V16.2882L0.983936 10.3856L0 11.3846L7.5 19L15 11.3846L14.0161 10.3856L8.18868 16.3026Z' fill='%23EAE5D0'/%3E%3C/svg%3E%0A") no-repeat center / 15px 19px;
					display: block;
					margin-top: 23px;
					size: 15px 19px;
				}

			}

			.list_person {
				display: block;
				margin: 0 auto;
				max-width: 478px;
				position: relative;
				width: 100%;
				z-index: 1;

				&:not(:last-of-type) { margin-bottom: 50px; }

				@media (--lg-up) { display: inline-block; }

				@media (--lg-up) {
					flex: 0 0 calc(50% - 50px);
					margin: 0;
				    max-width: none;
					width: calc(50% - 50px);

					&:nth-of-type(2n+2) { padding-top: 150px; }

					&:not(:last-of-type) { margin-bottom: 0; }

				}

				.person_inner {
					color: $base--text-colour;
					display: block;
					text-decoration: none;
					width: 100%;

					@media (--xl-up) {
						align-items: center;
						display: inline-flex;
						justify-content: flex-end;
					}

				}

					.person_img {
						background: #28182C;
						border-radius: 12px;
					    max-width: 478px;
						opacity: 0;
						transition:
							transform 1s ease-out-quart 0s,
							opacity 1s ease-out-quart 1s;
						transform: scale(1);
					    width: 100%;
						z-index: 1;

						&::before { padding-bottom: 114.44%; }

						&::after {
							background: linear-gradient(to bottom, rgba(32,21,43,0) 0%, #191024 100%);
							content: "";
							position: absolute * 0 0;
							size: 100% 80px;
							opacity: .4;
							z-index: 1;
						}

					}

						.img_src {
							@extend %u-absolute-fill, .u-cover-object;
							filter: grayscale(1);
							opacity: .5;
							transition: all 1s ease-out-quart;
							transform: scale(1);
							z-index: 0;
						}

					.person_meta {
						margin-top: -15px;
						opacity: 0;
						pointer-events: none;
						position: relative;
						transform: translateY(30px);
						transition: all 1s ease-out-quart 1s;
						z-index: 1;

						@media (--xl-up) {
							margin-top: 0;
							position: absolute * * 0 0;
							width: 100%;
						}

					}

						.meta_name {
							@extend .u-family--4;
							align-items: flex-start;
							display: flex;
							flex-direction: column;
						    font-size: responsive 21px 42px;
						    font-range: 320px 1280px;
							font-weight: 400;
							justify-content: flex-start;
							letter-spacing: -.035em;
						    line-height: 1em;
							position: relative;
							text-transform: uppercase;
						    white-space: nowrap;
							z-index: 1;
						}

							.meta_line {
								display: flex;

								&:not(:last-of-type) { margin-bottom: -.15em; }

							}

							.meta_highlight { @extend .u-text-style--1; }

						.meta_job {
							display: flex;
							font-size: 24px / 1em;
							margin-top: 13px;
							text-transform: uppercase;
						}

						.meta_arrow {
							background: #31283F url("data:image/svg+xml,%3Csvg width='9' height='14' viewBox='0 0 9 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L7 7L1 13' stroke='%23ECE5CB' stroke-width='2'/%3E%3C/svg%3E%0A") no-repeat center / 9px 14px;
							border-radius: 100%;
							display: flex;
							margin-top: 24px;
							pointer-events: auto;
							size: 48px;
							transition: .1s ease;
						}

				.person_img:hover {

					@media (--lg-up) {
						transform: scale(.95);

						.img_src { transform: scale(1.15); }

					}

				}

				&.is-inview {

					.person_img { opacity: 1; }

					.person_meta {
						opacity: 1;
						transform: translateY(0);
					}

				}

			}

			.list_backdrop {
				align-items: center;
				display: none;
				flex-direction: column;
				margin: 0;
				pointer-events: none;
				position: absolute 150px 0 *;
				text-align: center;
				user-select: none;
				width: 100%;
				z-index: 0;

				@media (--lg-up) {  display: flex; }

			}

				.backdrop_line {
					@extend .u-family--4;
					color: rgba(255,255,255,.03);
					display: flex;
					letter-spacing: -.025em;

					@media (--xl-up) { font-size: 370px !important; }

				}

}
