.c-header {
	color: #fff;
	display: block;
	font-size: 0;
	overflow: visible;

	/**
	* Inline pane
	*/
	.header_inline {
		align-items: center;
		background: rgba(25,16,36,0);
		backdrop-filter: blur(0);
		display: flex;
		justify-content: flex-start;
		padding: 24px 5vw 0;
		pointer-events: none;
		position: fixed 0 0 *;
		size: 100% 74px;
		transition:
			backdrop-filter .6s ease 0s,
			background .6s ease .3s,
			padding .6s ease .9s;
		transition-delay: 0s;
		z-index: 9999;

		@media (--lg-up) {
			height: 100px;
			padding: 44px 40px 0;
		}

		.inline_col {
			display: inline-flex;
			vertical-align: middle;
		}

		.inline_btn {
			flex: 0 0 calc(50% - 75px);
			justify-content: flex-start;
			text-align: left;
			transition: all .6s ease .3s;
			width: calc(50% - 75px);

			@media (--lg-up) {
				flex-basis: calc(50% - 130px);
				width: calc(50% - 130px);
			}

			@media (--xs-down) {

				.btn_link {
					padding: 0 !important;

					.o_text { padding: * 9px !important; }

				}

			}

		}

			.btn_link { pointer-events: auto; }

		.inline_logo {
			flex: 0 0 150px;
			justify-content: center;
			text-align: center;
			transition: all .6s ease .3s;

			@media (--lg-up) {
				flex-basis: 260px;
				width: 260px;
			}

		}

			.logo_link {
				display: inline-flex;
				margin: auto;
				pointer-events: auto;
				text-decoration: none;
				transition: all .6s ease .3s;
			}

				.logo_img {
					max-width: 150px;
					width: 100%;

					@media (--lg-up) { max-width: 260px; }

				}

		.inline_menu {
			flex: 0 0 calc(50% - 75px);
			justify-content: flex-end;
			text-align: right;
			width: calc(50% - 75px);

			@media (--lg-up) {
				flex-basis: calc(50% - 130px);
				width: calc(50% - 130px);
			}

		}

			.menu_btn {
				align-items: center;
				background: transparent;
				box-shadow: none;
				cursor: pointer;
				display: flex;
				justify-content: flex-start;
			    outline: 0;
				pointer-events: auto;

				.btn_icon {
					display: inline-flex;
					overflow: hidden;
					position: relative;
					size: 30px;
					vertical-align: middle;

					@media (--xs-down) { transform: scale(.85); }

				}

					i {
						height: 2px;
						position: absolute * * * 0;
					}

					.icon_to-open,
					.icon_to-exit {
						position: absolute 0;
						size: 100%;
					}

					.icon_to-open {

						i {
							background: #fff;
							transition: all .5s ease-out-quart .6s;
						}

						i:nth-of-type(1) { top: 6px; width: 30px; }
						i:nth-of-type(2) { top: 14px; width: 23px; }
						i:nth-of-type(3) { top: 22px; width: 30px; }

					}

					.icon_to-exit {
						opacity: 0;
						transform: rotate(-45deg);
						transform-origin: center;
						transition: all .5s ease-out-quart 0s;

						i {
							background: $c--6;
							margin: auto;
							position: 0;
							width: 28px;
						}

						i:nth-of-type(1) { transform: rotate(45deg); }
						i:nth-of-type(2) { transform: rotate(-45deg);  }

					}

				.btn_label {
					display: none;
					margin-left: 5px;
					overflow: hidden;
				    padding-right: 5px;
					position: relative;
					user-select: none;
					vertical-align: middle;

					@media (--xs-up) { display: inline-flex; }

				}

					.label_text {
						color: #fff;
						font-size: 15px / 1em;
						letter-spacing: .125em;
						margin-left: 10px;
						position: relative;
						text-transform: uppercase;
						transform: translateY(0);
						transition: transform .5s ease-in-out-quart;

						&::before {
							color: $c--6;
						    content: attr(data-exit-label);
						    position: absolute 100% * *;
						    transform: translateZ(0);
						}

					}

			}

	}

	/* Reveal logo on scroll */
	&.-logo-on-scroll {

		.logo_link { opacity: 0; }

		&.has-scrolled .logo_link {
			opacity: 1;
			transition-delay: 1.2s;
		}

	}

	/* Scrolled */
	&.has-scrolled .header_inline {
		background: rgba(25,16,36,.7);
		backdrop-filter: blur(20px);
		padding-top: 0 !important;
		pointer-events: auto;
		transition:
			padding .6s ease 0s,
			background .6s ease .6s,
			backdrop-filter .6s ease .9s;
	}

	/* Menu open */
	&.is-open .header_inline {
		background: rgba(25,16,36,0) !important;
		backdrop-filter: blur(0) !important;

		.inline_btn,
		.inline_logo {
			opacity: 0;
		}

		.menu_btn {

			.icon_to-open {

				i { width: 0 !important; }

				i:nth-of-type(1) { transition-delay: 0s; }
				i:nth-of-type(2) { transition-delay: .175s; }
				i:nth-of-type(3) { transition-delay: .35s; }

			}

			.icon_to-exit {
				opacity: 1;
				transform: rotate(0deg);
				transition-delay: .9s;
			}

			.label_text {
				transform: translateY(-100%) !important;
				transition-delay: .25s;
			}

		}

	}

	/**
	* Inline pane
	*/
	.header_nav {
		align-items: center;
		background: linear-gradient(90deg, $c--3 0%, $c--4 100%);
		clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
		display: flex;
		overflow: hidden;
		position: fixed 0;
		size: 100%;
		text-align: center;
		transition: all 1s ease-in-out-quart;
		z-index: -1;
		-ms-overflow-style: none;
		scrollbar-width: none;
		&::-webkit-scrollbar { display: none; }

		@media (--lg-up) { align-items: stretch; }

		.nav_inner {
			align-items: center;
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 0 5vw;
			position: relative;

			@media (--lg-up) { padding: * 40px; }

		}

			.nav_menu {
				align-items: center;
				display: inline-flex;
				flex-direction: column;
				justify-content: center;
				padding: 100px 0 0;

				@media (--lg-up) { flex: 1 1 auto; }

			}

				.item_link {
					@extend .u-family--3;
					align-items: center;
					color: $c--6;
					display: flex;
					flex-direction: column;
					font-size: responsive 34px 64px;
					font-range: 420px 1280px;
					font-weight: 400;
					justify-content: center;
					letter-spacing: -.035em;
					line-height: .875em;
					overflow: hidden;
					position: relative;
					text-align: center;
					text-decoration: none;
					text-transform: uppercase;
					white-space: nowrap;
					z-index: 1;

					@media (--lg-up) { padding: 0 20px; }

					@media (min-width: 760px) and (max-height: 730px) { font-size: 34px !important; }

				}

					.link_line {
						display: flex;
						position: relative;
						transform: translateY(100%);

						&::after {
							background: $c--3;
							border-radius: 4px;
							content: "";
							display: none;
							margin: auto;
							display: inline-block;
							position: absolute 0 -20px;
							size: calc(100% + 40px) 6px;
							transform: scaleX(0);
							transition: transform .6s ease-out-quart;
							transform-origin: right;
							z-index: 1;
						}

						@media (--lg-up) {
							margin: 0;

							&::after {
								content: "";
								display: block;
							}

							&:hover::after {
								transform: scaleX(1);
								transform-origin: left;
							}

						}

					}

						.line_l {
							position: relative;

							&:nth-of-type(2n+2) { z-index: 2; }

						}

			.nav_socials {
				align-items: center;
				display: inline-flex;
				flex-wrap: wrap;
				justify-content: center;
				margin: 70px auto;
				width: 100%;

				&:hover .socials_el svg { opacity: .4; }

			}

				.socials_el {
					align-items: center;
					border: 2px solid $c--3;
					border-radius: 100%;
					display: inline-flex;
					justify-content: center;
					size: 56px;
					pointer-events: auto;
					text-decoration: none;

					@media (min-width: 760px) and (min-height: 730px) { size: 88px; }

					&:not(:first-of-type) { margin-left: 3px; }
					&:not(:last-of-type) { margin-right: 3px; }

					@media (--md-up) {

						&:not(:first-of-type) { margin-left: 5px; }
						&:not(:last-of-type) { margin-right: 5px; }

					}

					&:hover svg { opacity: 1 !important; }

					svg {
						display: inline-flex;
						margin: auto;
						max-height: 20px;
						max-width: 20px;
						transition: all .5s ease;
						vertical-align: middle;

						@media (min-width: 760px) and (min-height: 730px) {
							max-height: 100%;
							max-width: 100%;
						}

						path { fill: #1B1025; }

					}

				}

	}

	/* Menu open */
	&.is-open .header_nav {
		clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
		transition-delay: 0s;
		z-index: 9998;
	}

}

/**
* Lazy-load
*/
html[data-page="home"] .c-header {

	.header_inline {
		opacity: 0;
		transform: translateY(-100%);
	}

}
