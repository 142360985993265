.c-text-aside-form {
	background: $c--7;
	display: block;
	font-size: 0;
	overflow: hidden;
	padding: 75px 0 60px;
	position: relative;
	text-align: center;
	width: 100%;
  margin: 6rem *;

	@media (--lg-up) {
		text-align: left;
		padding: 180px * 165px;
	}

	.c_inner {
		padding: 0 5vw;
		position: relative;

		@media (--lg-up) { padding: * 40px; }

	}

		.c_main {
			display: block;
			position: relative;
			width: 100%;
			z-index: 1;

			@media (--lg-up) {
				align-items: flex-start;
				display: flex;
				flex-wrap: nowrap;
				justify-content: flex-start;
			}

		}

			.c_col {
				width: 100%;

				@media (--lg-up) {
					flex: 0 0 50%;
					width: 50%;
				}

			}

			.c_content { display: block; }

				.content_inner {
					margin: 0 auto;
					max-width: 600px;
					text-align: center;

					@media (--lg-up) {
						padding-right: 100px;
						text-align: left;
					}

				}

					.content_upper-title {
						display: block;
						margin-bottom: 30px;
						position: relative;
						z-index: 1;
					}

					.content_heading {
						@extend .u-family--4;
						align-items: center;
						display: flex;
						flex-direction: column;
					    font-size: responsive 34px 66px;
					    font-range: 420px 1280px;
						font-weight: 400;
						justify-content: center;
						letter-spacing: -.035em;
					    line-height: .815em;
						position: relative;
						text-transform: uppercase;
						z-index: 1;

						@media (--lg-up) { align-items: flex-start; }

					}

						.heading_line { display: flex; }

						.heading_highlight { @extend .u-text-style--1; }

					.content_text {
					    color: $base--text-colour;
						display: block;
						margin: 25px 0 0;
						width: 100%;

						p {
							display: block;
						    font-size: responsive 20px 24px;
						    font-range: 420px 1280px;
						    line-height: 1.4em;

							&:not(:last-of-type) { margin-bottom: 1em; }

						}

					}

			.c_form {
				align-items: flex-start;
				display: flex;
				justify-content: center;
				margin-top: 40px;
				text-align: center;

				@media (--lg-up) {
					display: inline-flex;
					justify-content: flex-end;
					margin-top: 0;
					text-align: right;
				}

			}

				.form_inner {
					margin: 0 auto;
					max-width: 500px;
					position: relative;

					@media (--lg-up) { margin: 0; }

				}

					.form_response {
						align-items: center;
						background: transparent url('../images/form_bg.png') no-repeat top center / contain;
						display: flex;
						flex-direction: column;
						justify-content: center;
						opacity: 0;
						pointer-events: none;
						position: absolute 0;
						size: 100%;
						text-align: center;
						transition: all .6s ease-out-quart .6s;
						visibility: hidden;
						z-index: 0;
					}

						.response_text {
					        display: none;
							margin: 0 auto;
					        font-size: responsive 16px 18px;
					        font-range: 420px 1280px;
					        line-height: 1.6em;
						}

					.form_el {
						opacity: 1;
						pointer-events: auto;
						position: relative;
						transition: all .6s ease-out-quart;
						visibility: visible;
						width: 100%;
						z-index: 1;
					}

						.form_fields {
							display: flex;
							flex-wrap: wrap;
							justify-content: space-between;
							margin-bottom: 10px;
							width: 100%;
						}

							.fields_field {
								display: inline-block;
								flex: 1 1 100%;
								margin: 0;
								text-align: left;
								overflow: visible;
								position: relative;
								vertical-align: top;
								width: 100%;
								z-index: 1;

								&:not(:last-of-type) { margin-bottom: 50px; }

								&.-half {
									flex: 0 1 calc(50% - 13px);
									width: calc(50% - 13px);
								}

								&::before,
								&::after {
									content: '';
									display: block;
									position: absolute * 0 0;
									size: 100% 1px;
									transition: transform .3s;
									transition-delay: .3s;
								}

								&::before { background: rgba(255,255,255,.2); }

								&::after {
								    background-image: linear-gradient(to right, $c--3, $c--4);
								    background-image: -webkit-linear-gradient(to right, $c--3, $c--4);
									transform: scaleX(0);
									transform-origin: right;
									z-index: 1;
								}

							}

								.input_field {
									appearance: none;
									background: transparent;
									border: 0 none;
									border-radius: 0;
									color: $base--text-colour;
									display: block;
									font-size: 16px / 1em;
									opacity: 0;
									outline: none;
									padding: 15px 0;
									position: relative;
									width: 100%;

									&:focus { outline: none; }

								}

								.input_label {
									@extend %absolute-fill;
									align-items: flex-start;
									color: $base--text-colour;
									display: flex;
									justify-content: flex-start;
									padding: 15px 0;
									pointer-events: none;
									position: absolute 0;
									size: 100%;
									text-align: left;
									-webkit-touch-callout: none;
									user-select: none;
								}

									.input_label-content {
										display: inline-flex;
										font-size: 16px / 1em;
										position: relative;
										transition: all .3s ease .3s;
									}

							.fields_field {

								.input_field:focus,
								&.is-filled .input_field {
									opacity: 1;
									transition-delay: .3s;
								}

								.input_field:focus + .input_label .input_label-content,
								&.is-filled .input_label-content {
									font-size: 16px !important;
									opacity: .55;
									transform: translateY(-25px);
									transition-delay: 0s;
								}

								&.is-filled::after {
									transform: scaleX(1);
									transform-origin: left;
								}

							}

						.form_footer {
							display: block;
							width: 100%;

							@media (--lg-up) {
								display: flex;
								flex-wrap: wrap;
								justify-content: space-between;
							}

						}

            .form_captcha {
              padding: 20px 0px;
            }

							.footer_notices {
								display: flex;
								flex: 1 1 100%;
								width: 100%;
							}

							.footer_consent {
								display: flex;
								flex: 1 1 100%;
								justify-content: center;
								margin-bottom: 20px;
								text-align: left;

								@media (--lg-up) {
									align-items: center;
									display: inline-flex;
									justify-content: flex-start;
									flex: 0 0 auto;
									margin-bottom: 0;
									max-width: 250px;
								}

								.consent_field {
									display: none;
									margin: 0;
									position: absolute;
									size: 0;
								}

								.consent_label {
									align-items: center;
									cursor: pointer;
									display: inline-flex;
									font-size: 14px / 1.45em;
									justify-content: flex-start;
									min-height: 14px;
									padding-left: 30px;
									position: relative;

									&::before {
										background-color: transparent;
										background-repeat: no-repeat;
										background-position: center;
										border-radius: 0;
										border: 1px solid rgba(255,255,255,.2);
										content: "";
										position: absolute .15em * * 0;
										size: 14px;
									}

								}

								.consent_field:checked + .consent_label::before { background-color: $c--1; }

							}

							.footer_submit {
								display: flex;
								flex: 1 1 100%;
								justify-content: center;
								text-align: center;

								@media (--lg-up) {
									align-items: center;
									display: inline-flex;
									justify-content: flex-end;
									flex: 0 0 auto;
									padding-left: 50px;
									text-align: right;
								}

							}

	/**
	* Form submitted
	*/
	&.is-submitting {

		.form_el { opacity: .35; }

	}

	&.has-submitted {

		.form_response {
			opacity: 1;
			pointer-events: auto;
			visibility: visible;
			z-index: 1;
		}

		.form_el {
			opacity: 0 !important;
			pointer-events: none;
			visibility: hidden;
			z-index: 0;
		}

		&.is-success .response_text.-success { display: block; }
		&.is-error .response_text.-error { display: block; }

	}

}
