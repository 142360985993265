.c-video-modal {
    align-items: center;
	background: rgba(25,16,36,.7);
	backdrop-filter: blur(20px);
    cursor: pointer;
    display: flex;
    justify-content: center;
    opacity: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 100px 4vw;
    pointer-events: none;
    position: fixed 0;
    size: 100%;
    text-align: center;
    visibility: hidden;
    z-index: -1;
	-ms-overflow-style: none;
	scrollbar-width: none;
	&::-webkit-scrollbar { display: none; }

    @media (--lg-up) { padding: 50px; }

    &.is-open { pointer-events: auto; }

    .modal_inner {
        margin: auto;
        max-height: 675px;
        max-width: 900px;
        width: 100%;
    }

        .modal_exit-btn {
            background: #31283F url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 6L6 18' stroke='%23EAE5D0' stroke-width='1.5' stroke-linecap='square' stroke-linejoin='round'/%3E%3Cpath d='M6 6L18 18' stroke='%23EAE5D0' stroke-width='1.5' stroke-linecap='square' stroke-linejoin='round'/%3E%3C/svg%3E%0A") no-repeat center / 24px;
            border-radius: 100%;
            cursor: pointer;
            display: flex;
            margin: 0 auto;
            position: absolute * 0 50px;
            size: 48px;
            text-align: center;
            transition: .1s ease;
            z-index: 1;

            @media (--lg-up) {
                bottom: auto;
                top: 50px;
            }

        }

        .modal_video {
            margin: auto;
            pointer-events: none;
            position: relative;
            width: 100%;
            z-index: 0;
        }

}
