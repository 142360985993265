.c-bio {
	background: $base--bg-colour;
	display: block;
	font-size: 0;
	overflow: visible;
	position: relative;
	text-align: left;
	width: 100%;

	.c_inner {
		display: block;
		position: relative;
		z-index: 1;

		@media (--xl-up) {
			align-items: stretch;
			display: flex;
			flex-direction: row-reverse;
			flex-wrap: nowrap;
			justify-content: flex-start;
		}

	}

		.c_col {
			display: block;
			width: 100%;

			@media (--xl-up) {
				display: flex;
				flex: 0 0 50%;
				min-height: 100vh;
				width: 50%;
			}

		}

		.c_image {
			background: #28182C;
			overflow: hidden;
			z-index: 0;

			@media (min-width: 1024px) and (max-width: 1230px) { margin-top: 150px; }

			@media (min-width: 620px) and (max-width: 1230px) {
				border-radius: 12px;
				margin: * auto;
				max-width: 600px;
				text-align: center;
			}

			@media (min-width: 620px) and (max-width: 1024px) { margin-top: 100px; }

		}

			.image_src {
				@extend %u-absolute-fill, .u-cover-object;
				filter: grayscale(1);
				opacity: .5;
			}

		.c_content {
			background: transparent;
			margin-top: -15px;
			padding: 0 4vw 60px;
			position: relative;
			z-index: 1;

			@media (--xl-up) {
				margin-top: 0;
				padding: 230px 50px 100px;
			}

		}

			.content_inner {
				display: block;
				margin: 0 auto;
				max-width: 550px;
			}

			.content_heading {
				@extend .u-family--4;
				align-items: flex-start;
				display: flex;
				flex-direction: column;
				font-size: responsive 21px 50px;
				font-range: 320px 1280px;
				font-weight: 400;
				justify-content: flex-start;
				letter-spacing: -.035em;
				line-height: 1em;
				margin-bottom: 16px;
				position: relative;
				text-transform: uppercase;
				white-space: nowrap;
			}

				.heading_line {
					display: flex;

					&:not(:last-of-type) { margin-bottom: -.15em; }

				}

				.heading_highlight { @extend .u-text-style--1; }

			.content_sub {
				color: rgba(234,229,208,.55);
				display: flex;
				font-size: 20px / 1em;
				margin-bottom: 20px;
				text-transform: uppercase;
			}

			.content_description {
				display: block;
				padding-bottom: 50px;
			}

			.content_social {
				align-items: flex-start;
				border-top: 1px solid rgba(255,255,255,.2);
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				padding-top: 24px;

				@media (--sm-up) {
					align-items: center;
					flex-direction: row;
				}

			}

				.social_label {
					@extend .u-family--4;
					display: flex;
					font-size: 19px / 1em;
					margin-bottom: 15px;
					text-transform: uppercase;

					@media (--xl-up) {
						display: inline-flex;
						margin: 0 22px 0 0;
					}

				}

				.social_links {
					align-items: center;
					display: flex;
					justify-content: flex-start;
					text-align: center;

					&:hover .links_icon { opacity: .4; }

				}

					.links_icon {
						align-items: center;
						background: transparent;
						border: 2px solid #D72B59;
						border-radius: 100%;
						display: inline-flex;
						justify-content: center;
						margin-right: 8px;
						size: 60px;
						transition: opacity .5s ease;

						&:hover { opacity: 1 !important; }

						.icon_src {
							display: inline-flex;
							margin: auto;
							max-height: 20px;
							max-width: 20px;
							text-align: center;
						}

					}

			.content_return {
				border-top: 1px solid rgba(255,255,255,.2);
				display: block;
				margin-top: 24px;
				padding-top: 24px;
			}

				.return_btn {
					align-items: center;
					color: #fff;
					display: inline-flex;
					justify-content: flex-start;
					text-decoration: none;

					.btn_arrow {
						background: #31283F url("data:image/svg+xml,%3Csvg width='9' height='15' viewBox='0 0 9 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 13.6289L2 7.62891L8 1.62891' stroke='%23ECE5CB' stroke-width='2'/%3E%3C/svg%3E%0A") no-repeat center / 9px 15px;
						border-radius: 100%;
						display: flex;
						pointer-events: auto;
						position: relative;
						size: 48px;
						transition: .1s ease;
						z-index: 0;
					}

					.btn_label {
						@extend .u-family--2;
						display: inline-flex;
						font-size: 13px / 1em;
						padding-left: 13px;
						position: relative;
						text-transform: uppercase;
						z-index: 1;
					}

				}

}
