.c-textarea {
	background: $base--bg-colour;
	display: block;
	font-size: 0;
	overflow: visible;
	position: relative;
	text-align: center;
	width: 100%;

	.c_inner {
		padding: 0 5vw 140px;
		position: relative;
		z-index: 1;

		@media (--lg-up) { padding: 0 40px 190px; }

	}

		.c_body {
			margin: 0 auto;
			max-width: 920px;
		}

}
