/**
* Primary
*/
.o-btn--1 {
    appearance: none;
    align-items: center;
    background: transparent;
    border: 2px solid $c--3;
    border-radius: 43px;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    outline: 0;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    transition: all .3s ease-out-quart;
    user-select: none;

    &::before {
        background-image: linear-gradient(to right, rgba(215,43,89,.5), rgba(251,232,81,.5));
        border-radius: 43px;
        content: "";
        display: block;
        opacity: 0;
        position: absolute 3px;
        size: calc(100% - 6px);
        transition: all .3s ease-out-quart;
        z-index: 0;
    }

    .o_icon {
        background: $c--3;
        border-radius: 100%;
        flex: 0 0 50px;
        position: relative;
        size: 50px;
        z-index: 1;

        &.-play::before {
            background: transparent url("data:image/svg+xml,%3Csvg width='11' height='11' viewBox='0 0 11 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11 5.5L0 11L0 0L11 5.5Z' fill='%23EEEEEE'/%3E%3C/svg%3E%0A") no-repeat center / 11px;
            content: "";
            display: inline-block;
            margin: auto;
            position: absolute 0;
            transform: translateX(1px);
        }

    }

    .o_text {
        @extend .u-family--3;
        align-items: center;
        color: $base--text-colour;
        display: inline-flex;
        justify-content: center;
        letter-spacing: .05em;
        line-height: 1em;
        margin: auto;
        padding: 2px * 0;
        position: relative;
        text-align: center;
        text-decoration: inherit;
        text-transform: uppercase;
        vertical-align: middle;
        z-index: 1;
    }

    /* Hover */
    &:not(.-disabled):hover::before { opacity: 1; }

    /* Modifiers */
    &.-height--1 {
        min-height: 66px;
        padding: 6px;
    }

    &.-height--2 {
        min-height: 30px;
        padding: 2px;

        @media (--lg-up) { min-height: 48px; }

    }

    &.-height--3 { min-height: 55px; }

    &.-fsz--1 .o_text {
        font-size: 10px;
        padding: * 20px;

        @media (--lg-up) { font-size: 12px; }

    }

    &.-fsz--2 .o_text {
        font-size: 9px;
        padding: * 12px;

        @media (--lg-up) {
            font-size: 12px;
            padding: * 20px;
        }

    }

    &.-fsz--3 .o_text {
        font-size: 13px;
        padding: * 20px;

        @media (--lg-up) { padding: * 30px; }

    }

    &.-disabled {
        border-color: rgba(255,255,255,.5);
        cursor: no-drop;
        opacity: .35;

        .o_text { color: rgba(255,255,255,.45); }

    }

}
