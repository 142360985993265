.c-hero {
	background: $base--bg-colour;
	display: block;
	font-size: 0;
	padding: 250px 0 100px;
	position: relative;
	text-align: center;

	@media (--lg-up) { padding: 270px * 100px; }

	.c_inner {
		padding: 0 5vw;
		position: relative;

		@media (--lg-up) { padding: * 40px; }

	}

		.hero_heading {
			@extend .u-family--4;
			align-items: center;
			display: flex;
			flex-direction: column;
			font-size: responsive 34px 125px;
			font-range: 420px 1280px;
			font-weight: 400;
			justify-content: center;
			letter-spacing: -.035em;
			line-height: 1em;
			position: relative;
			text-transform: uppercase;
			z-index: 1;

			@media (--lg-up) { align-items: center; }

		}

			.heading_line {
				display: flex;

				&:not(:last-of-type) { margin-bottom: -.15em; }

			}

			.heading_highlight { @extend .u-text-style--1; }

		.hero_descr {
			color: $base--text-colour;
			display: block;
			margin: 25px auto 0;
			max-width: 720px;
			width: 100%;

			@media (--lg-up) { margin-top: 48px; }

			p {
				display: block;
				font-size: responsive 20px 24px;
				font-range: 420px 1280px;
				letter-spacing: -.025em;
				line-height: 1.4em;

				&:not(:last-of-type) { margin-bottom: 1em; }

			}

		}

		.hero_backdrop {
			@extend .u-family--4;
			color: rgba(255,255,255,.05);
			letter-spacing: -.025em;
			margin: 0;
			pointer-events: none;
			position: absolute -50px * * calc(50% - 820px);
			text-align: center;
			user-select: none;
			z-index: 0;
		}

	/**
	* Modifiers
	*/
	&.-align-left {

		@media (--lg-up) { text-align: left; }

		.hero_heading {

			@media (--lg-up) { align-items: flex-start; }

		}

		.hero_descr { margin: * 0; }

	}

}
