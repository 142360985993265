/**
* Families
*/
.u-family--1 { font-family: 'Space Grotesk'; } /* Space Grotesk */
.u-family--2 { font-family: 'owners-wide'; } /* Owners Wide */
.u-family--3 { font-family: 'owners-xwide'; } /* Owners X Wide */
.u-family--4 { font-family: 'owners-xxwide'; } /* Owners XX Wide */


/**
* Text styles
*/
.u-text-style--1 {
    background-image: linear-gradient(0deg, $c--3, $c--4);
    background-image: -webkit-linear-gradient(0deg, $c--3, $c--4);
    background-clip: text;
    -webkit-background-clip: text;
    background-color: transparent;
    color: $c--4;
    text-fill-color: transparent;
    -webkit-text-fill-color: transparent;
    user-select: none;
}

.u-text-style--2 {
    color: $base--text-colour;
    font-size: responsive 14px 17px;
    font-range: 420px 1280px;
    letter-spacing: 3px;
    line-height: 1em;
    text-transform: uppercase;
}

.u-text-style--3 {
    color: $base--text-colour;
    display: inline-flex;
    font-size: responsive 100px 235px;
    font-range: 420px 1280px;
    letter-spacing: 3px;
    line-height: 1em;
    text-transform: uppercase;
    white-space: nowrap;
}


.u-text-group--1 {

    h2 {
        @extend .u-family--4;
        align-items: center;
        display: flex;
        flex-direction: column;
        font-size: responsive 24px 30px;
        font-range: 420px 1280px;
        font-weight: 400;
        justify-content: center;
        letter-spacing: -.035em;
        line-height: 1.25em;
        margin: 0 auto 1.5em;
        position: relative;
        text-transform: uppercase;
        z-index: 1;
    }

    ul + h2, ol + h2, p + h2 { margin-top: 50px; }

    h3 {
        font-size: responsive 20px 25px;
        font-range: 420px 1280px;
        font-weight: 400;
        line-height: 1.6em;
        margin: 0 auto 1.5em;
    }

    ul + h3, ol + h3, p + h3 { margin-top: 40px; }

    p {
        display: block;
        font-size: responsive 16px 18px;
        font-range: 420px 1280px;
        line-height: 1.6em;

        &:not(:last-of-type) { margin-bottom: 1em; }

    }

    ul + p, ol + p { margin-top: 30px; }

    b, strong { font-weight: 400; }
    u { text-decoration: underline; }
    s { text-decoration: line-through; }

    a {
        color: $c--3;
        text-decoration: none;
        transition: color .2s ease;

        &:hover {
            color: $c--3;
            text-decoration: underline;
        }

    }

    p + ul, p + ol { margin-top: 30px; }

    ul, ol {
        align-items: center;
        display: flex;
        flex-direction: column;
        padding-left: 0;

        li {
            counter-increment: li;
            display: inline-flex;
            margin-bottom: 1em;
            position: relative;

            &:not(:last-of-type) { margin-bottom: 15px; }

        }

        li::before {
            color: $base--text-colour;
            content: "\2022";
            display: inline-block;
            font-size: responsive 16px 18px;
            font-range: 420px 1280px;
            line-height: 1.6em;
            position: absolute 0 * * 0;
        }

    }

    ul {

        li {
            padding-left: 22px;

            &::before { width: 22px; }

        }

            ul { margin: 14px * 16px *; }

    }

    ol {
        counter-reset: li;

        li {
            padding-left: 35px;

            &::before {
                border-radius: 100%;
                color: inherit;
                content: counter(li) ".";
                display: inline-block;
                position: absolute 0 * * 0;
                text-align: left;
                vertical-align: top;
            }

        }

            ol, ul {
                margin-bottom: 35px;
                margin-left: 0;
                padding-left: 62px;
                padding-top: 20px;
            }

    }

}


/**
* Font sizes
*/
.u-font-size--1 {
    font-size: responsive 90px 160px;
    font-range: 420px 1280px;
    line-height: 1em;
}
