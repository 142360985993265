.c-play {
  background: $base--bg-colour;
  display: block;
  font-size: 0;
  overflow: visible;
  position: relative;
  width: 100%;
  /* height: calc(100vh - 40px); */
  /* height: calc(calc(var(--vh, 1vh) * 100) - 40px); */
  margin: 0 0 30px;

  @media (--lg-up) {
    margin: * * 60px;
    /* min-height: 1000px; */
  }

  /* &::before {
    background: linear-gradient(180deg, #191024 0%, rgba(25, 16, 36, .45) 50%, #191024 100%);
    content: "";
    display: block;
    position: absolute * 0 -1px;
    size: 100% 101%;
  } */

  .canvas_media {
    overflow: hidden;
    pointer-events: none;
    user-select: none;
    z-index: -1;
    text-align: center;

    &::before { padding-bottom: 100%; }

    .media_image {
      object-fit: cover;
    }
  }

  .content_container {
    display: block;
    align-items: center;

    @media (--lg-up) {
      display: flex;
    }
  }

  .text_container {
    width: 100%;
    text-align: center;

    @media (--lg-up) {
      width: 40%;
      text-align: left;
      padding-left: 30px;
    }
  }

  .image_container {
    width: 100%;

    @media (--lg-up) {
      width: 60%;
    }
  }

  .c_content {
    display: block;
    margin: 0 auto 30px;
    padding: 0 5vw;
    position: relative;
    text-align: center;

    @media (--lg-up) {
      margin-bottom: 85px;
      padding: * 40px;
    }

    .content_upper-title {
      color: $base--text-colour;
      font-size: responsive 30px 60px;
      font-range: 420px 2100px;
      letter-spacing: 3px;
      line-height: 1em;
      text-transform: uppercase;
      margin-top: 1em;
      
      display: block;
      position: relative;
      z-index: 1;
    }

    .content_heading {
      @extend .u-family--4;
      align-items: center;
      display: flex;
      flex-direction: column;
      font-size: responsive 34px 78px;
      font-range: 420px 1280px;
      font-weight: 400;
      justify-content: center;
      letter-spacing: -.035em;
      line-height: .875em;
      margin-top: 30px;
      position: relative;
      text-transform: uppercase;
      z-index: 1;
    }

    .heading_line {
      display: flex;
      z-index: 1;
    }

    .head_backdrop {
      @extend .u-family--4;
      color: rgba(255, 255, 255, .05);
      letter-spacing: -.025em;
      margin: 0;
      pointer-events: none;
      position: absolute 0 * * calc(50% - 820px);
      text-align: center;
      user-select: none;
      z-index: 0;
    }

    .heading_highlight {
      @extend .u-text-style--1;
    }

    .content_text {
      color: $base--text-colour;
      display: block;
      margin: 30px auto 30px;
      max-width: 625px;
      width: 100%;

      p {
        display: block;
        font-size: responsive 20px 24px;
        font-range: 420px 1280px;
        line-height: 1.4em;

        &:not(:last-of-type) {
          margin-bottom: 1em;
        }

      }

    }

    .content_backdrop {
      @extend .u-family--4;
      color: rgba(255, 255, 255, .05);
      letter-spacing: -.025em;
      margin: 0;
      position: absolute 20px * * calc(50% - 820px);
      text-align: center;
      z-index: 0;
    }

  }
}
