.o-scroll {
	overflow: hidden;
	position: relative;
	z-index: 2;

	> .scroll_top-prop {
		background: transparent;
		opacity: 0;
		size: 1px 0;
		z-index: -1;
	}

}
