/**
* Example
*/
@keyframes u-animation--1 {
	0% { opacity: 0; }
	100% { opacity: 1; }
}

@keyframes u-animation_gradient--1 {
	0% { background-position: 0 50%; }
	50% { background-position: 100% 50%; }
	100% { background-position: 0 50%; }
}

@keyframes u-animation_rotate--1 {
	from { transform: rotate(0deg); }
	to { transform: rotate(360deg); }
}
