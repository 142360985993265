.c-team-intro {
	background: $base--bg-colour;
	display: block;
	font-size: 0;
	overflow: visible;
	position: relative;
	width: 100%;

	.c_content {
		display: block;
		margin: 0 auto 30px;
		padding: 0 5vw;
		position: relative;
		text-align: center;

		@media (--lg-up) {
			margin-bottom: 85px;
			padding: * 40px;
		}

		.content_upper-title {
			display: block;
			margin-bottom: 20px;
			position: relative;
			z-index: 1;

			@media (--lg-up) { margin-bottom: 40px; }

		}

		.content_heading {
			@extend .u-family--4;
			align-items: center;
			display: flex;
			flex-direction: column;
		    font-size: responsive 34px 85px;
		    font-range: 420px 1280px;
			font-weight: 400;
			justify-content: center;
			letter-spacing: -.035em;
		    line-height: .875em;
			margin-top: 30px;
			position: relative;
			text-transform: uppercase;
			z-index: 1;
		}

			.heading_line { display: flex; }

			.heading_highlight { @extend .u-text-style--1; }

		.content_text {
			color: $base--text-colour;
			display: block;
			margin: 30px auto 0;
			max-width: 625px;
			width: 100%;

			p {
				display: block;
				font-size: responsive 20px 24px;
				font-range: 420px 1280px;
				line-height: 1.4em;

				&:not(:last-of-type) { margin-bottom: 1em; }

			}

		}

		.content_backdrop {
			@extend .u-family--4;
			color: rgba(255,255,255,.05);
			letter-spacing: -.025em;
			margin: 0;
			position: absolute 20px * * calc(50% - 820px);
			text-align: center;
			z-index: 0;
		}

	}

	.c_list {
		margin-top: 45px;
		position: relative;

		@media (--lg-up) { margin-top: 85px; }

		.list_inner {
			padding: 0 5vw;
			position: relative;
			z-index: 1;

			@media (--lg-up) { padding: * 40px; }

		}

			.flickity-viewport { overflow: visible; }

				.list_carousel {
					display: block;
					width: 100%;

					@media (--lg-up) {
						display: grid;
						opacity: 1;
						grid-gap: 45px;
						grid-template-columns: repeat(3, 1fr);
					}

					&::after {
						content: 'flickity';
						display: none;

						@media (--lg-up) { content: ''; }

					}

				}

					.carousel_item {
						background: #28182C;
						border-radius: 18px;
						color: $base--text-colour;
						filter: drop-shadow(0 52px 64px rgba(0,0,0,.2));
						max-width: 325px;
						margin-right: 16px;
						min-height: 426px;
						overflow: visible;
						position: relative;
						text-decoration: none;
						user-select: none;
						width: 100%;

						@media (--xl-down) { transform: none !important; }

						@media (--lg-up) {
							margin-right: 0;
							max-width: none;
							min-height: 500px;
						}

					}

						.item_caption {
							@extend %u-absolute-fill;
							align-items: center;
							display: flex;
							justify-content: center;
							text-align: center;
							z-index: 1;
						}

							.caption_text {
								@extend .u-family--3;
								color: $base--text-colour;
								display: inline-flex;
							    font-size: responsive 19px 24px;
							    font-range: 420px 1280px;
								letter-spacing: .015em;
							    line-height: 1em;
								margin: auto;
								text-transform: uppercase;
							}

						.item_media {
							@extend %u-absolute-fill;
							border-radius: 18px;
							overflow: hidden;
							z-index: 0;
						}

							.media_img {
								@extend %u-absolute-fill, .u-cover-object;
								opacity: .5;
								transition: all 2s ease-out-quart;
								transform: scale(1);
							}

					.carousel_item:hover .media_img { transform: scale(1.15); }

	}

			.list_arrows {
				align-items: center;
				display: flex;
				flex-wrap: nowrap;
				justify-content: center;
				margin-top: 35px;
				position: relative;
				text-align: center;
				width: 100%;
				z-index: 1;

				@media (--lg-up) {
					display: none;
					margin-top: 0;
				}

			}

				.arrows_btn {
					background: transparent;
					border: 1px solid $c--3;
					cursor: pointer;
					display: inline-block;
					flex: 0 0 68px;
					overflow: hidden;
					position: relative;
					size: 68px;
					transition: all .3s ease;

					&::before {
						background: transparent url('../images/carousel_arrow--1.svg') no-repeat center / 10px 18px;
						content: "";
						margin: auto;
						position: absolute 0;
						size: 15px 11px;
						transform-origin: center;

						@media (--lg-up) {
							background-size: 13px 23px;
							size: 20px 14px;
						}

					}

					&.-prev { margin-right: -1px; }
					&.-prev::before { transform: rotate(-180deg); }

					&.is-disabled {
						cursor: no-drop;
						opacity: .3;
					}

			}

}
