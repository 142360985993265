/**
* Lazy-load
*/
.u-lazy-load--1 {
	opacity: 0;
	transition: opacity .8s ease-in-out-quart .05s;

	&.is-inview { opacity: 1; }

}

[data-scroll-section-inview] .u-lazy-load--1 { opacity: 1 !important; }
