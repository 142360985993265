/***************
* Space Grotesk
***************/
@font-face {
  font-family: 'Space Grotesk';
  src: url('../fonts/SpaceGrotesk-VariableFont_wght.ttf') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
