/**
* Create ratio-bound content blocks, to keep media (e.g. images, videos) in
* their correct aspect ratios.
*
* http://alistapart.com/article/creating-intrinsic-ratios-for-video
*
* 1. Default cropping is a 1:1 ratio (i.e. a perfect square).
*/
.o-ratio {
    display: block;
    overflow: hidden;
    position: relative;

    &::before {
		content: "";
        display: block;
        width: 100%;
    }

}

.o-ratio_content,
.o-ratio > img,
.o-ratio > iframe,
.o-ratio > embed,
.o-ratio > object {
    margin: auto;
    position: absolute 0 * * 0;
    width: 100%;
    height: 100%;
}
