.o-footer-bg {
	align-items: center;
	display: flex;
	justify-content: center;
	margin: auto;
	pointer-events: none;
	position: absolute calc(100% - 80px) 0 *;
	user-select: none;
	z-index: 0;

	@media (--md-up) { top: calc(100% - 200px); }

	> .o_img {
		animation: u-animation_rotate--1 100s linear infinite;
		display: inline-block;
		margin: auto;
		max-height: 420px;
		max-width: 385px;
		opacity: .32;
		text-align: center;
		width: 100%;

		@media (--md-up) {
			max-height: 1015px;
			max-width: 930px;
		}

	}

}
