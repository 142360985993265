/**
* Cover
*/
.u-cover-bg {
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

.u-cover-object {
	object-fit: cover;
	object-position: center;
}
