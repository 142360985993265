.c-poster-carousel {
	background: $base--bg-colour;
	display: block;
	font-size: 0;
	overflow: hidden;
	position: relative;
	width: 100%;

	.c_inner {
		padding: 0 5vw;
		position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

		@media (--lg-up) { padding: * 40px; }

	}

		.c_content {
			display: block;
			margin: 0 auto 30px;
			text-align: center;

			@media (--lg-up) { margin-bottom: 85px; }

			.content_upper-title {
				display: block;
				margin-bottom: 20px;
				position: relative;
				z-index: 1;

				@media (--lg-up) { margin-bottom: 40px; }

			}

      .sub_heading {
        color: $base--text-colour;
        font-size: responsive 30px 60px;
        font-range: 420px 2100px;
        letter-spacing: 3px;
        line-height: 1em;
        text-transform: uppercase;
        margin-top: 1em;
      }

			.content_heading {
				@extend .u-family--4;
				align-items: center;
				display: flex;
				flex-direction: column;
			    font-size: responsive 34px 85px;
			    font-range: 420px 1280px;
				font-weight: 400;
				justify-content: center;
				letter-spacing: -.035em;
			    line-height: .875em;
				margin-top: 30px;
				position: relative;
				text-transform: uppercase;
				z-index: 1;
			}

				.heading_line { display: flex; }

				.heading_highlight { @extend .u-text-style--1; }

			.content_backdrop {
				@extend .u-family--4;
				color: rgba(255,255,255,.05);
				letter-spacing: -.025em;
				margin: 0;
				pointer-events: none;
				position: absolute 20px * * calc(50% - 820px);
				text-align: center;
				user-select: none;
				z-index: 0;
			}

      .content_text {
        color: $base--text-colour;
        display: block;
        margin: 30px auto 0;
        max-width: 625px;
        width: 100%;
  
        p {
          display: block;
          font-size: responsive 20px 24px;
          font-range: 420px 1280px;
          line-height: 1.4em;
  
          &:not(:last-of-type) { margin-bottom: 1em; }
  
        }
  
      }

		}

    .width_short {
      width: 80% !important;
    }

		.c_carousel {
			display: block;
			text-align: left;
			width: 100%;

			.flickity-viewport {
				overflow: visible;

				@media (--xl-up) {

					&.is-pointer-down .carousel_item {
						transform: scale(.9);
						transition-delay: 0s;
					}

				}

			}

				.flickity-slider {
					align-items: stretch;
					display: flex;
				}

					.carousel_item {
						background: transparent;
						display: inline-flex;
						flex-direction: column;
						margin: * calc($gutter--mobile / 4);
						max-width: 920px;
					    min-height: 100%;
						padding: 0;
						opacity: .5;
						overflow: visible;
						position: relative;
						transform: scale(1);
						transition:
							opacity .8s ease-in-out-quart 0s,
							transform .8s ease-out-quart .4s;
						width: 100%;

						@media (--xl-up) {
							display: block;
							margin: * 70px * 0;
							max-width: 1140px;
							padding: 0 275px 50px 0;
						}

						&.is-selected { opacity: 1; }

					}

          .carousel_item2 {
            background: transparent;
						display: inline-flex;
						flex-direction: column;
						margin: * calc($gutter--mobile / 4);
						max-width: 920px;
					    min-height: 100%;
						padding: 0;
						opacity: .5;
						overflow: visible;
						position: relative;
						transform: scale(1);
						transition:
							opacity .8s ease-in-out-quart 0s,
							transform .8s ease-out-quart .4s;
						width: 100%;

						@media (--xl-up) {
							display: block;
							margin: * 0 * 0;
							max-width: 1140px;
						}

						&.is-selected { opacity: 1; }
          }

						.item_media {
							background: $c--5;
							flex: 0 0 auto;
							margin: 0 auto;
							max-width: 920px;
							position: relative;
							width: 100%;
							z-index: 0;

							&::before { padding-bottom: 66.30%; }

						}

							.media_img { @extend %u-absolute-fill, .u-cover-object; }

						.item_content {
							align-items: flex-start;
							color: $c--6 $c--1;
							display: inline-flex;
							flex: 1 1 auto;
							justify-content: flex-start;
							margin: 0 auto;
							max-width: 920px;
							overflow: visible;
							padding: 50px 34px 40px;
							position: relative;
							width: 100%;
							z-index: 1;

							@media (--xl-up) {
								align-items: flex-end;
								margin: 0;
								max-width: 650px;
								position: absolute * 0 0 *;
							}

							@media (--lg-up) { padding: 100px 100px 90px; }

						}

							.content_heading {
								@extend .u-family--3;
								display: flex;
							    font-size: responsive 28px 35px;
							    font-range: 420px 1280px;
								font-weight: 400;
								letter-spacing: -.035em;
							    line-height: .85em;
								margin: 0 0 30px;
								position: relative;
								text-transform: uppercase;
								z-index: 1;

								&::before {
									@extend .u-family--2;
									color: transparent;
									content: attr(data-count);
									font-size: 90px / 1em;
									opacity: .6;
									position: absolute -90px * * 0;
									text-stroke: 1.3px $c--3;
									-webkit-text-stroke: 1.3px $c--3;
									z-index: -1;

									@media (--lg-up) {
										font-size: 130px;
										left: -155px;
										top: -155px;
									}

								}

							}

							.content_text {
								position: relative;
								z-index: 1;

								p {
									display: block;
								    font-size: responsive 16px 17px;
								    font-range: 420px 1280px;
								    line-height: 1.4em;

									&:not(:last-of-type) { margin-bottom: 1em; }

								}

							}

		}

		.c_controls {
			align-items: stretch;
			display: flex;
			flex-wrap: nowrap;
			justify-content: flex-start;
		    margin: 60px auto 0;
            position: relative;
            size: 100% 68px;

			@media (--lg-up) { margin-top: 90px; }

        }

			.controls_arrows {
				align-items: stretch;
				display: inline-flex;
				flex: 0 0 136px;
				flex-wrap: nowrap;
				justify-content: flex-start;
				position: relative;
				text-align: left;
				width: 136px;
				z-index: 1;
			}

				.arrows_btn {
					background: transparent;
					border: 1px solid $c--3;
					cursor: pointer;
					display: inline-block;
					flex: 1 1 50%;
					overflow: hidden;
					position: relative;
					size: 68px;
					transition: all .3s ease;

					&::before {
						background: transparent url('../images/carousel_arrow--1.svg') no-repeat center / 10px 18px;
						content: "";
						margin: auto;
						position: absolute 0;
						size: 15px 11px;
						transform-origin: center;

						@media (--lg-up) {
							background-size: 13px 23px;
							size: 20px 14px;
						}

					}

					&.-prev { margin-right: -1px; }
					&.-prev::before { transform: rotate(-180deg); }

					&.is-disabled {
						cursor: no-drop;
						opacity: .3;
					}

				}

			.control_bar {
				align-items: center;
				background: transparent;
				display: inline-flex;
				flex: 1 1 calc(100% - 136px);
				justify-content: center;
				margin: auto;
				overflow: hidden;
				padding: 0 0 0 30px;
				position: relative;
				size: calc(100% - 136px) 68px;
	            z-index: 0;

				@media (--lg-up) {
					padding-left: 40px;
					position: * 0;
				}

			}

				.bar_line {
					background: rgba(151,151,151,.2);
					margin: auto;
					max-width: 920px;
					position: relative;
					size: 100% 1px;
				}

					.line_current {
						background: $c--3;
						display: block;
						margin: auto;
						max-width: 920px;
						position: absolute 0 * 0 0;
						size: 0% 1px;
						transition: width .6s ease-in-out;
					}

}
