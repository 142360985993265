.c-footer {
	background: $c--5;
	display: block;
	font-size: 0;
	overflow: hidden;
	position: relative;
	text-align: center;
	width: 100%;
	z-index: 1;

	.c_inner {
		padding: 0 5vw;
		position: relative;

		@media (--lg-up) { padding: * 40px; }

	}

		.c_main {
			display: block;
			padding: 50px 0 30px;
			width: 100%;

			@media (--lg-up) {
				align-items: flex-start;
				display: flex;
				justify-content: center;
				padding: 80px 0 100px;
				text-align: left;
			}

			.main_col {
				align-items: flex-start;
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				width: 100%;

				@media (--lg-up) { flex-wrap: nowrap; }

			}

			.main_logo {

				@media (--lg-up) {
					display: inline-flex;
					flex: 0 0 320px;
					width: 320px;
				}

			}

				.logo_link, .logo_src {
					display: inline-flex;
					margin: auto;
					text-decoration: none;
				}

					.logo_src {
						max-width: 320px;
						width: 100%;
					}

			.main_links {
				margin-top: 35px;

				@media (--xxl-up) { padding: * 100px * 50px; }

				@media (--lg-up) {
					align-items: flex-start;
					display: inline-flex;
					flex: 1 1 calc(100% - 320px);
					justify-content: flex-end;
					margin-top: 0;
					padding: 0 0 0 80px;
					width: calc(100% - 320px);
				}

			}

				.links_menu {
					display: block;
					flex: 1 1 100%;
					width: 100%;

					@media (--lg-up) {
						flex: 0 1 50%;
						max-width: 320px;
						width: 100%;
					}

					&:not(:first-of-type) .menu_item:first-of-type .item_link {
						margin-top: .75em;

						@media (--lg-up) { margin-top: 0; }

					}

				}

					.menu_item {
						align-items: center;
						display: flex;
						justify-content: center;

						@media (--lg-up) { justify-content: flex-start; }

						&:not(:first-of-type) .item_link { margin-top: .75em; }

					}

						.item_link {
							@extend .u-family--3;
							color: $base--text-colour;
							display: inline-flex;
						    font-size: responsive 18px 25px;
						    font-range: 420px 1280px;
						    line-height: 1em;
							margin: 0 auto;
							overflow: visible;
							position: relative;
							text-decoration: none;

							&::after {
								background-image: linear-gradient(to right, $c--3, $c--4);
							    border-radius: 2px;
								content: "";
								display: none;
								margin: auto;
								display: inline-block;
								position: absolute 0 -6px;
								size: calc(100% + 12px) 3px;
								transform: scaleX(0);
								transition: transform .6s ease-out-quart;
								transform-origin: right;
								z-index: 1;
							}

							@media (--lg-up) {
								margin: 0;

								&::after {
									content: "";
									display: block;
								}

								&:hover::after {
									transform: scaleX(1);
									transform-origin: left;
								}

							}

						}

		}

		.footer_bottom {
			border-top: 1px solid rgba(151,151,151,.2);
			display: block;
			padding: 30px 0 38px;
			text-align: center;
			width: 100%;

			@media (--lg-up) {
				align-items: center;
				display: flex;
				justify-content: center;
				flex-wrap: nowrap;
			}

		}

			.bottom_col {
				align-items: center;
				flex: 1 1 100%;
				display: flex;
				justify-content: center;

				@media (--lg-up) {
					display: inline-flex;
					flex: 0 0 auto;
				}

			}

			.bottom_links {
				margin-top: 8px;

				@media (--lg-up) { margin-top: 0; }

			}

				.links_item {
					display: inline-flex;

					.item_link {
						overflow: visible;
						padding-left: 12px;
						position: relative;

						&::before {
							content: "|";
							display: inline-flex;
							font-size: inherit;
							margin-right: 12px;
						}

						&::after {
							background-image: linear-gradient(to right, $c--3, $c--4);
						    border-radius: 1px;
							content: "";
							display: none;
							margin: auto 0;
							display: inline-block;
							position: absolute 0 -4px 0 22px;
							size: calc(100% - 16px) 2px;
							transform: scaleX(0);
							transition: transform .4s ease-out-quart;
							transform-origin: right;
							z-index: 1;
						}

						@media (--lg-up) {

							&::after {
								content: "";
								display: block;
							}

							&:hover::after {
								transform: scaleX(1);
								transform-origin: left;
							}

						}
					}

					&:first-of-type .item_link::before {
						display: none !important;
						@media (--lg-up) { display: inline-flex !important; }
					}

				}

					.bottom_text {
						color: $base--text-colour;
					    font-size: responsive 14px 17px;
					    font-range: 420px 1280px;
					    line-height: 1em;
						text-decoration: none;
					}

}
