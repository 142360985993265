.c-tile-carousel {
	background: $base--bg-colour;
	display: block;
	font-size: 0;
	overflow: hidden;
	padding: 70px 0 40px;
	position: relative;
	text-align: center;
	width: 100%;

	@media (--lg-up) {
		padding-bottom: 75px;
	}

  .content_sub_heading {
    color: $base--text-colour;
    font-size: responsive 14px 30px;
    font-range: 420px 2100px;
    letter-spacing: 3px;
    line-height: 1.5em;
    text-transform: uppercase;
    margin-top: 1em;
  }

	.c_main, .c_carousel .carousel_inner {
		padding: 0 5vw;
		position: relative;

		@media (--lg-up) { padding: * 40px; }

	}

		.main_content {
			display: block;
			width: 100%;
		}

			.content_upper-title {
				display: block;
				margin-bottom: 30px;
				padding-top: 10px;
				position: relative;
				z-index: 1;

				@media (--lg-up) { margin-bottom: 50px; }

			}

			.content_heading {
				@extend .u-family--4;
				align-items: center;
				display: flex;
				flex-direction: column;
			    font-size: responsive 29px 84px;
			    font-range: 420px 1280px;
				font-weight: 400;
				justify-content: center;
				letter-spacing: -.035em;
			    line-height: .875em;
				position: relative;
				text-align: center;
				text-transform: uppercase;
				z-index: 1;

			}

				.heading_line { display: flex; }

				.heading_highlight { @extend .u-text-style--1; }

        .head_backdrop {
          @extend .u-family--4;
          color: rgba(255,255,255,.05);
          letter-spacing: -.025em;
          margin: 0;
          pointer-events: none;
          position: absolute 0 * * calc(50% - 820px);
          text-align: center;
          user-select: none;
          z-index: 0;
        }

			.content_text {
			    color: $base--text-colour;
				display: block;
				margin: 30px auto 0;
				max-width: 680px;
				width: 100%;

				@media (--lg-up) { margin: * 0; }

				p {
					display: block;
				    font-size: responsive 20px 24px;
				    font-range: 420px 1280px;
				    line-height: 1.4em;

					&:not(:last-of-type) { margin-bottom: 1em; }

				}

			}

			.content_btn { margin: 0 1rem 0; }

		.c_carousel {
      max-width: 1400px;
			margin-top: 35px;
      margin-left: auto;
      margin-right: auto;
			overflow: hidden;
			position: relative;

			&::before,
			&::after {
				display: none;
				pointer-events: none;
				position: absolute 0 *;
				size: 200px 100%;
				z-index: 1;

				@media (--lg-up) {
					content: "";
					display: block;
				}

			}

			&::before { background-image: linear-gradient(to right, #191024, rgba(25,16,36,0)); left: 0; }
			&::after { background-image: linear-gradient(to left, #191024, rgba(25,16,36,0)); right: 0; }

			@media (--lg-up) { margin-top: 72px; }

		}

			.carousel_slides .flickity-viewport {
				overflow: visible;

				@media (--xl-up) {

					&.is-pointer-down .slides_item {
						transform: scale(.9);
						transition-delay: 0s;
					}

				}

			}

				.slides_item {
					border-radius: 42px;
					margin: 0 10px;
					overflow: hidden;
					position: relative;
					size: 282px;
					transform: scale(1);
					transition: transform .8s ease-out-quart .4s;

					@media (--lg-up) {
						border-radius: 22px;
						margin: * 25px * 0;
						size: 362px;
					}

				}

					.item_img {
						@extend %u-absolute-fill, .u-cover-object;
						transform: scale(1);
						transition: transform .8s ease-out-quart 0s;
					}

				.slides_item:hover .item_img { transform: scale(1.085); }

		.carousel_controls {
			align-items: stretch;
			display: flex;
			flex-wrap: nowrap;
			justify-content: flex-start;
			margin: 60px auto 0;
			position: relative;
			size: 100% 68px;

			@media (--lg-up) { margin-top: 90px; }

		}

			.controls_arrows {
				align-items: stretch;
				display: inline-flex;
				flex: 0 0 136px;
				flex-wrap: nowrap;
				justify-content: flex-start;
				position: relative;
				text-align: left;
				width: 136px;
				z-index: 1;
			}

				.arrows_btn {
					background: transparent;
					border: 1px solid $c--3;
					cursor: pointer;
					display: inline-block;
					flex: 1 1 50%;
					overflow: hidden;
					position: relative;
					size: 68px;
					transition: all .3s ease;

					&::before {
						background: transparent url('../images/carousel_arrow--1.svg') no-repeat center / 10px 18px;
						content: "";
						margin: auto;
						position: absolute 0;
						size: 15px 11px;
						transform-origin: center;

						@media (--lg-up) {
							background-size: 13px 23px;
							size: 20px 14px;
						}

					}

					&.-prev { margin-right: -1px; }
					&.-prev::before { transform: rotate(-180deg); }

					&.is-disabled {
						cursor: no-drop;
						opacity: .3;
					}

				}

			.control_bar {
				align-items: center;
				background: transparent;
				display: inline-flex;
				flex: 1 1 calc(100% - 136px);
				justify-content: center;
				margin: auto;
				overflow: hidden;
				padding: 0 0 0 30px;
				position: relative;
				size: calc(100% - 136px) 68px;
				z-index: 0;

				@media (--lg-up) {
					padding-left: 40px;
					position: * 0;
				}

			}

				.bar_line {
					background: rgba(151,151,151,.2);
					margin: auto;
					max-width: 920px;
					position: relative;
					size: 100% 1px;
				}

					.line_current {
						background: $c--3;
						display: block;
						margin: auto;
						max-width: 920px;
						position: absolute 0 * 0 0;
						size: 0% 1px;
						transition: width .6s ease-in-out;
					}

            .btn_link {
              margin-right: 20px;
              margin-bottom: 20px;
    
              @media(--lg-up) {
                margin-bottom: 0px;
              }
            }
}
