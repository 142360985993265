/**
* Absolute space fill
*/
%u-absolute-fill {
    position: absolute 0 * * 0;
    size: 100%;
}


/**
* Absolute anchor
*/
%u-absolute-anchor {
	@extend %u-absolute-fill;
    color: transparent;
    font-size: 0;
    text-indent: -999px;
}
