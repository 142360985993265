.c-text-aside-img {
	background: $base--bg-colour;
	display: block;
	font-size: 0;
	overflow: hidden;
	padding: 70px 0;
	position: relative;
	width: 100%;

	@media (--lg-up) { padding: 180px *; }

	.c_inner {
		padding: 0 5vw;
		position: relative;

		@media (--lg-up) { padding: * 40px; }

	}

		.c_main {
			display: block;
			position: relative;
			width: 100%;
			z-index: 1;

			@media (--lg-up) {
				align-items: flex-start;
				display: flex;
				flex-wrap: nowrap;
				justify-content: flex-start;
			}

		}

			.c_content {
				display: block;
				width: 100%;

				@media (--lg-up) {
					flex: 0 0 50%;
					padding-top: 70px;
					width: 50%;
				}

			}

				.content_inner {
					text-align: center;

					@media (--lg-up) {
						max-width: 600px;
						padding-right: 100px;
						text-align: left;
					}

				}

					.content_upper-title {
						display: block;
						margin-bottom: 30px;
						position: relative;
						z-index: 1;
					}

					.content_heading {
						@extend .u-family--4;
						align-items: center;
						display: flex;
						flex-direction: column;
					    font-size: responsive 34px 72px;
					    font-range: 420px 1280px;
						font-weight: 400;
						justify-content: center;
						letter-spacing: -.035em;
					    line-height: .875em;
						position: relative;
						text-transform: uppercase;
						z-index: 1;

						@media (--lg-up) { align-items: flex-start; }

					}

						.heading_line { display: flex; }

						.heading_highlight { @extend .u-text-style--1; }

					.content_text {
					    color: $base--text-colour;
						display: block;
						margin: 30px auto 0;
						max-width: 680px;
						width: 100%;

						@media (--lg-up) { margin: * 0; }

						p {
							display: block;
						    font-size: responsive 20px 24px;
						    font-range: 420px 1280px;
						    line-height: 1.4em;

							&:not(:last-of-type) { margin-bottom: 1em; }

						}

					}

					.content_btn { margin-top: 40px; }

			.c_media {
				align-items: flex-start;
				display: flex;
				justify-content: center;
				margin-top: 40px;
				opacity: .75;
				text-align: center;
				width: 100%;

        &::before { margin-bottom: 111% }

				@media (--lg-up) {
					display: inline-flex;
					flex: 0 0 50%;
					justify-content: flex-end;
					margin-top: 0;
					text-align: right;
					width: 50%;
				}

			}

		.c__backdrop {
			@extend .u-family--4;
			color: rgba(255,255,255,.05);
			letter-spacing: -.025em;
			margin: 0;
			position: absolute 75px * * calc(50% - 820px);
			text-align: center;
			z-index: 0;
		}

}
