.c-form {
	background: $base--bg-colour;
	display: block;
	font-size: 0;
	overflow: visible;
	position: relative;
	text-align: center;
	width: 100%;

	.c_form {
		padding: 70px 5vw 140px;
		position: relative;
		z-index: 1;

		@media (--lg-up) { padding: 70px 40px 190px; }

		&::before {
			background: transparent url('../images/footer_bg.png') no-repeat top center / contain;
			content: "";
			margin: 0 auto;
			pointer-events: none;
			position: absolute 0 0 *;
			size: 100%;
			z-index: 0;

			@media (--lg-up) { size: 1042px 957px; }

		}

	}

		.form_base {
			backdrop-filter: blur(27px);
			background: rgba(34,26,45,.5);
			border: 1px solid #372B45;
			border-radius: 10px;
			display: block;
			margin: auto;
			max-width: 808px;
			padding: 55px 25px;
			position: relative;
			width: 100%;
			z-index: 1;

			@media (--lg-up) { padding: 65px; }

		}

			.form_response {
				align-items: center;
				display: flex;
				flex-direction: column;
				justify-content: center;
				opacity: 0;
				padding: 70px 30px;
				pointer-events: none;
				position: absolute 0;
				size: 100%;
				text-align: center;
				transition: all .6s ease-out-quart .6s;
				visibility: hidden;
				z-index: 0;
			}

				.response_text {
			        display: none;
					margin: 0 auto;
			        font-size: responsive 16px 18px;
			        font-range: 420px 1280px;
			        line-height: 1.6em;
				}

				.response_socials {
					align-items: center;
					display: inline-flex;
					flex-wrap: wrap;
					justify-content: center;
					margin: 40px auto 0;

					&:hover .socials_el svg { opacity: .4; }

				}

					.socials_el {
						align-items: center;
						border: 1px solid $c--3;
						border-radius: 100%;
						display: inline-flex;
						justify-content: center;
						size: 48px;
						pointer-events: auto;
						text-decoration: none;

						@media (--md-up) { size: 88px; }

						&:not(:first-of-type) { margin-left: 5px; }
						&:not(:last-of-type) { margin-right: 5px; }

						&:hover svg { opacity: 1 !important; }

						svg {
							display: inline-flex;
							margin: auto;
							max-height: 20px;
							max-width: 20px;
							transition: all .5s ease;
							vertical-align: middle;

							@media (--md-up) {
								max-height: 100%;
								max-width: 100%;
							}

							path { fill: #fff; }

						}

					}

			.form_body {
				opacity: 1;
				pointer-events: auto;
				transition: all .6s ease-out-quart;
				visibility: visible;
				z-index: 0;
			}

				.fields_field {
					display: inline-block;
					margin: 0;
					text-align: left;
					overflow: visible;
					position: relative;
					vertical-align: top;
					width: 100%;
					z-index: 1;

					&:not(:last-of-type) { margin-bottom: 50px; }

					&::before,
					&::after {
						content: '';
						display: block;
						position: absolute * 0 0;
						size: 100% 1px;
						transition: transform .3s;
						transition-delay: .3s;
					}

					&::before { background: rgba(255,255,255,.2); }

					&::after {
					    background-image: linear-gradient(to right, $c--3, $c--4);
					    background-image: -webkit-linear-gradient(to right, $c--3, $c--4);
						transform: scaleX(0);
						transform-origin: right;
						z-index: 1;
					}

				}

					.input_field {
						appearance: none;
						background: transparent;
						border: 0 none;
						border-radius: 0;
						color: $base--text-colour;
						display: block;
						font-size: 16px / 1em;
						opacity: 0;
						outline: none;
						padding: 15px 0;
						position: relative;
						width: 100%;

						&:focus { outline: none; }

						&:-webkit-autofill {
							background: transparent !important;
							border: 0 none !important;
						}

						&:autofill {
							background: transparent !important;
							border: 0 none !important;
						}

					}

					textarea.input_field {
						resize: none;
						-ms-overflow-style: none;
						scrollbar-width: none;

						&::-webkit-scrollbar { display: none; }

					}

					.input_label {
						@extend %absolute-fill;
						align-items: flex-start;
						color: $base--text-colour;
						display: flex;
						justify-content: flex-start;
						padding: 15px 0;
						pointer-events: none;
						position: absolute 0;
						size: 100%;
						text-align: left;
						-webkit-touch-callout: none;
						user-select: none;
					}

						.input_label-content {
							display: inline-flex;
							font-size: 16px / 1em;
							position: relative;
							transition: all .3s ease .3s;
						}

					.fields_field {
						width: 100%;

						.input_field:focus,
						&.is-filled .input_field {
							opacity: 1;
							transition-delay: .3s;
						}

						.input_field:focus + .input_label .input_label-content,
						&.is-filled .input_label-content {
							font-size: 16px !important;
							opacity: .55;
							transform: translateY(-25px);
							transition-delay: 0s;
						}

						&.is-filled::after {
							transform: scaleX(1);
							transform-origin: left;
						}

					}

				.form_footer {
					display: block;
					margin-top: 40px;
					width: 100%;

					@media (--lg-up) {
						display: flex;
						flex-wrap: wrap;
						justify-content: space-between;
					}

				}

					.footer_notices {
						display: flex;
						flex: 1 1 100%;
						width: 100%;
					}

					.footer_consent {
						display: flex;
						flex: 1 1 100%;
						justify-content: center;
						margin-bottom: 20px;
						text-align: left;

						@media (--lg-up) {
							align-items: center;
							display: inline-flex;
							justify-content: flex-start;
							flex: 0 0 auto;
							margin-bottom: 0;
							max-width: 350px;
						}

						.consent_field {
							display: none;
							margin: 0;
							position: absolute;
							size: 0;
						}

						.consent_label {
							align-items: center;
							cursor: pointer;
							display: inline-flex;
							font-size: 14px / 1.45em;
							justify-content: flex-start;
							min-height: 14px;
							padding-left: 30px;
							position: relative;

							&::before {
								background-color: transparent;
								background-repeat: no-repeat;
								background-position: center;
								border-radius: 0;
								border: 1px solid rgba(255,255,255,.2);
								content: "";
								position: absolute .15em * * 0;
								size: 14px;
							}

						}

						.consent_field:checked + .consent_label::before { background-color: $c--1; }

					}

					.footer_submit {
						display: flex;
						flex: 1 1 100%;
						justify-content: center;
						text-align: center;

						@media (--lg-up) {
							align-items: center;
							display: inline-flex;
							justify-content: flex-end;
							flex: 0 0 auto;
							padding-left: 50px;
							text-align: right;
						}

					}

						.submit_btn {
							text-align: center;
							width: 100%;

							@media (--lg-up) {
								min-width: 186px;
								width: auto;
							}

						}

	/**
	* Form submitted
	*/
	&.is-submitting {

		.form_body { opacity: .35; }

	}

	&.has-submitted {

		.form_response {
			opacity: 1;
			pointer-events: auto;
			visibility: visible;
			z-index: 1;
		}

		.form_body {
			opacity: 0 !important;
			pointer-events: none;
			visibility: hidden;
			z-index: 0;
		}

		&.is-success .response_text.-success { display: block; }
		&.is-error .response_text.-error { display: block; }

	}

}
