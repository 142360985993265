.o-cursor {
	opacity: 0;

	@media (--lg-down) { display: none !important; }

	/**
	* Text
	*/
	.cursor_text {
		@extend .u-family--1;
		align-items: center;
		background: rgba(25,16,36,.7);
		border-radius: 100%;
		color: #fff;
		display: flex;
		font-size: 15px / 1em;
		justify-content: center;
		letter-spacing: .0525em;
		margin: auto;
		padding: 0 15px;
		pointer-events: none;
		position: fixed -52px * * -50px;
		size: 100px;
		transform: scale(0);
		z-index: 100000;
	}

	/**
	* Inner
	*/
	.cursor_inner {
		background-color: #fff;
		border-radius: 100%;
		mix-blend-mode: difference;
		opacity: 0;
		pointer-events: none;
		position: fixed -4px * * -4px;
		size: 6px;
		z-index: 99999;
	}

	/**
	* Outer
	*/
	.cursor_outer {
		border: 1px solid rgba(255,255,255,.2);
		border-radius: 100%;
		position: fixed -31px * * -31px;
		size: 60px;
		pointer-events: none;
		user-select: none;
		z-index: 99999;
	}

}

.no-js .o-cursor { display: none; }

/**
* Lazy-load
*/
html.is-ready .o-cursor {
	opacity: 1;
	transition: opacity .6s ease 1s;
}
